<template>
  <div class="home">
    <section class="container mobile-adjusted page-top">
      <h1>TikTok Engagement Rate Calculator</h1>
      <p>
        Analyze TikTok influencers: follower count,<br />
        engagement rate, views, popular content and more.
      </p>
    </section>

    <section id="engagement-calculator" class="container mobile-adjusted">
      <div id="engagement-form">
        <input
          type="string"
          required="required"
		  v-model="username"
          placeholder="Enter TikTok @username"
        />
        <button @click="submit" type="submit" :disabled="!username">
          <div v-if="loading" class="lds-ring"><div></div><div></div><div></div><div></div></div>
		  <span v-else>Check Profile</span>
        </button>
      </div>
	  <template v-if="showResults" >
		  <div id="engagement-result" class="card row">
        <div class="col-xs-12 col-md-4 profile-info">
          <img :src="user.avatar" :alt="user.username" />
          <p class="username" @click="openProfile">@{{ user.username }}</p>
          <div class="stats row">
            <div class="col-xs-4">
              <p>{{ formatFollowing(user.followers) }}</p>
              <span>Followers</span>
            </div>
            <div class="col-xs-4">
              <p>{{ formatFollowing(user.following) }}</p>
              <span>Following</span>
            </div>
            <div class="col-xs-4">
              <p>{{ formatLikes(user.total_likes) }}</p>
              <span>Likes</span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-8 posts-info">
          <div class="row">
            <div class="col-xs-12 col-sm-4 stats engagement">
              <div class="card">
                <svg
                  width="19"
                  height="21"
                  viewBox="0 0 19 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.555 20.8202V13.7113C7.555 13.5479 7.37868 13.4171 7.15828 13.4171H0.392096C0.08354 13.4171 -0.103797 13.172 0.0615003 12.9759L10.5524 0C11.0152 0.0980545 10.9711 0.0898833 11.434 0.179767V7.28872C11.434 7.45214 11.6103 7.58288 11.8307 7.58288H18.6079C18.9165 7.58288 19.1038 7.82802 18.9385 8.02412L8.43658 21C7.97375 20.9101 8.01783 20.9183 7.555 20.8202Z"
                  ></path>
                </svg>
                <p>{{ formatLikes(user.engagement) }}%</p>
                <span>Engagement Rate</span>
              </div>
            </div>
            <div class="col-xs-6 col-sm-4 stats likes">
              <div class="card">
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.798 1.09275C13.4817 1.06171 11.448 2.10372 10.7676 4.11432C10.1414 2.0862 8.13634 0.990069 5.82002 0.959025C2.90977 0.920021 0.562401 3.2053 0.523397 6.11555C0.475637 9.67913 2.26349 13.6831 10.5694 18.9032C19.0717 13.9084 20.9068 9.95295 20.9546 6.38938C20.9936 3.47912 18.7083 1.13176 15.798 1.09275Z"
                  ></path>
                </svg>
                <p>{{ formatLikes(user.avg_likes) }}</p>
                <span>Avg. Likes</span>
              </div>
            </div>
            <div class="col-xs-6 col-sm-4 stats comments">
              <div class="card">
                <svg
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.8138 8.24757L2.96384 0.630111C1.63093 -0.102987 0 0.861336 0 2.38254V17.6175C0 19.1387 1.63093 20.103 2.96384 19.3699L16.8138 11.7524C18.1953 10.9926 18.1953 9.00742 16.8138 8.24757Z"
                    fill="#D0B593"
                  ></path>
                </svg>
                <p>{{ formatLikes(user.avg_views) }}</p>
                <span>Avg. Views</span>
              </div>
            </div>
            <div class="col-xs-12 col-md-6 graph">
              <div class="card">
                <p>Likes per post</p>
				<svg aria-hidden="true" focusable="false" style="width: 0px; height: 0px; position: absolute;">
					<defs>
						<linearGradient id="graphFill" x1="1" x2="1" y1="0" y2="1">
							<stop offset="0%" stop-color="#E7AEC0"></stop>
							<stop offset="100%" stop-color="#FAECDB"></stop>
						</linearGradient>
					</defs>
				</svg>
				<trend-chart
					:datasets="[{
						data: datasetLikes,
						fill: true,
						smooth: true,
						showPoints: false,
						className: 'curve-inbeat'
					}]"
                    :labels="labels"
                   	:min="0"
                   	:grid="grid">
				</trend-chart>
              </div>
            </div>
            <div class="col-xs-12 col-md-6 graph">
              <div class="card">
                <p>Comments per post</p>
				<svg aria-hidden="true" focusable="false" style="width: 0px; height: 0px; position: absolute;">
					<defs>
						<linearGradient id="graphFill" x1="1" x2="1" y1="0" y2="1">
							<stop offset="0%" stop-color="#E7AEC0"></stop>
							<stop offset="100%" stop-color="#FAECDB"></stop>
						</linearGradient>
					</defs>
				</svg>
				<trend-chart
					:datasets="[{
						data: datasetComments,
						fill: true,
						smooth: true,
						showPoints: false,
						className: 'curve-inbeat'
					}]"
                    :labels="labels"
                   	:min="0"
                   	:grid="grid">
				</trend-chart>
              </div>
            </div>

            <div class="col-xs-12 col-md-12 posts">
              <div class="card">
                <p class="card-title">Recent posts</p>
                <div class="row">

				  <div class="col-xs-6 col-sm-4 col-md-3" v-for="(post, index) in posts" :key="index">
                    <div class="post" @click="openPost(post.url)">
                      <div
                        class="img"
						:style="{ backgroundImage: `url(${post.image})` }"
                      >
                        <div class="views">
                          <svg
                            width="7"
                            height="8"
                            viewBox="0 0 7 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.25 3.56699C6.58333 3.75944 6.58333 4.24056 6.25 4.43301L1.75 7.03109C1.41667 7.22354 1 6.98298 1 6.59808L1 1.40192C1 1.01702 1.41667 0.776461 1.75 0.968911L6.25 3.56699Z"
                              stroke="white"
                            ></path></svg
                          >{{ formatLikes(post.video_views) }}
                        </div>
                      </div>
                      <p>
                        <span
                          ><svg
                            width="22"
                            height="20"
                            viewBox="0 0 22 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.798 1.09275C13.4817 1.06171 11.448 2.10372 10.7676 4.11432C10.1414 2.0862 8.13634 0.990069 5.82002 0.959025C2.90977 0.920021 0.562401 3.2053 0.523397 6.11555C0.475637 9.67913 2.26349 13.6831 10.5694 18.9032C19.0717 13.9084 20.9068 9.95295 20.9546 6.38938C20.9936 3.47912 18.7083 1.13176 15.798 1.09275Z"
                            ></path>
                          </svg>
                          {{ formatLikes(post.likes) }}</span
                        >
                        <span
                          ><svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18.5727 15.1309C19.6233 13.3826 20.1564 11.2971 19.9604 9.10975C19.4665 3.59818 14.6056 -0.45513 9.10191 0.0387937C3.59818 0.540558 -0.455129 5.4014 0.038795 10.8973C0.532719 16.3932 5.4014 20.4543 10.8973 19.9604C12.528 19.8114 14.0255 19.2783 15.3191 18.4629L19.3018 19.6311C19.6233 19.7252 19.9134 19.4273 19.8193 19.1058L18.5727 15.1309Z"
                            ></path>
                          </svg>
                          {{ formatLikes(post.comments) }}</span
                        >
                        <span
                          ><svg
                            width="19"
                            height="21"
                            viewBox="0 0 19 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.555 20.8202V13.7113C7.555 13.5479 7.37868 13.4171 7.15828 13.4171H0.392096C0.08354 13.4171 -0.103797 13.172 0.0615003 12.9759L10.5524 0C11.0152 0.0980545 10.9711 0.0898833 11.434 0.179767V7.28872C11.434 7.45214 11.6103 7.58288 11.8307 7.58288H18.6079C18.9165 7.58288 19.1038 7.82802 18.9385 8.02412L8.43658 21C7.97375 20.9101 8.01783 20.9183 7.555 20.8202Z"
                            ></path>
                          </svg>
                          {{ formatLikes(post.engagement) }}%</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
	  </template>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import axios from "axios";
import TrendChart from "vue-trend-chart";

Vue.use(TrendChart);

const notCharRegex = /[^\w\d.-]/g;
export default {
  name: "Home",
  data() {
    return {
      username: "",
      user: {},
	  showResults: false,
      posts: [],
      datasetLikes: [],
      datasetComments: [],
      labels: {
        xLabels: [],
        yLabels: 5,
        yLabelsTextFormatter: (val) => this.formatLikes(val),
      },
      grid: {
        verticalLines: true,
        verticalLinesNumber: 1,
        horizontalLines: true,
        horizontalLinesNumber: 5,
      },
      loading: false,
    };
  },
  methods: {
    submit() {
      const username = this.username.replace(notCharRegex, "");
      if (username == "") {
        this.error = "Please enter a username.";
        return;
      }

      this.loading = true;
	  this.showResults = false;

	  axios.post("tiktok-analytics", { username }).then(({ data }) => {
		  this.user = data.data.user;

		  const posts = data.data.posts;
		  this.posts = posts;

		  if (posts) {
			this.labels.xLabels = [...posts].slice(0, 12).map((_, i) => i + 1);
			this.datasetLikes = [...posts].slice(0, 12).map((post) => post.likes);
			this.datasetComments = posts.map((post) => post.comments);
		  }

		  this.loading = false;
		  this.showResults = true;
	  }).finally(() => {
		  this.loading = false;
    });

        // res = JSON.parse(request.response);
        // _this.posts = res.posts;
        // _this.error = null;
        // _this.loading = false;
    },
    formatFollowing(nb) {
      if (nb < 10000) {
        return Math.ceil(nb);
      }

      if (nb < 1000000) {
        const newNb = Math.ceil(nb / 100);
        return `${newNb / 10}k`;
      }

      const newNb = Math.ceil(nb / 10000);
      return `${newNb / 100}M`;
    },
    formatLikes(nb) {
      if (nb < 1000) {
        return !isNaN(nb) ? Math.ceil(nb) : 0;
      }

      if (nb < 10000) {
        const newNb = Math.ceil(nb / 10);
        return `${newNb / 100}k`;
      }

      if (nb < 100000) {
        const newNb = Math.ceil(nb / 100);
        return `${newNb / 10}k`;
      }

      if (nb < 1000000) {
        return `${Math.ceil(nb / 1000)}k`;
      }

      if (nb < 10000000) {
        const newNb = Math.ceil(nb / 10000);
        return `${newNb / 100}M`;
      }

      const newNb = Math.ceil(nb / 100000);
      return `${newNb / 10}M`;
    },
    openPost(url) {
      const win = window.open(url, "_blank");
      win.focus();
    },
    openProfile() {
      const win = window.open(
        "https://www.tiktok.com/@" + this.user.username,
        "_blank"
      );
      win.focus();
    },
  },
  delimiters: ["{", "}"],
};
</script>

<style lang="scss">
@import url("https://p.typekit.net/p.css?s=1&k=quj6ejz&ht=tk&f=15759.15760.15761.22737.22738.39512.39518.39519.39521.39523&a=7546024&app=typekit&e=css");

@font-face {
  font-family: "merriweather";
  src: url("https://use.typekit.net/af/627a5e/00000000000000003b9b38e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/627a5e/00000000000000003b9b38e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/627a5e/00000000000000003b9b38e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "merriweather";
  src: url("https://use.typekit.net/af/b50009/00000000000000003b9b38e2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b50009/00000000000000003b9b38e2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b50009/00000000000000003b9b38e2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "merriweather";
  src: url("https://use.typekit.net/af/08f652/00000000000000003b9b38e1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/08f652/00000000000000003b9b38e1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/08f652/00000000000000003b9b38e1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "merriweather";
  src: url("https://use.typekit.net/af/bcff88/00000000000000003b9b38e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/bcff88/00000000000000003b9b38e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/bcff88/00000000000000003b9b38e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "merriweather";
  src: url("https://use.typekit.net/af/ddc542/00000000000000003b9b38e0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ddc542/00000000000000003b9b38e0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ddc542/00000000000000003b9b38e0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "neue-haas-unica";
  src: url("https://use.typekit.net/af/6a58f8/00000000000000003b9b2058/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/6a58f8/00000000000000003b9b2058/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/6a58f8/00000000000000003b9b2058/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "neue-haas-unica";
  src: url("https://use.typekit.net/af/18efc2/00000000000000003b9b205e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/18efc2/00000000000000003b9b205e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/18efc2/00000000000000003b9b205e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "neue-haas-unica";
  src: url("https://use.typekit.net/af/fff0e7/00000000000000003b9b205f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/fff0e7/00000000000000003b9b205f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/fff0e7/00000000000000003b9b205f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "neue-haas-unica";
  src: url("https://use.typekit.net/af/dff101/00000000000000003b9b2061/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/dff101/00000000000000003b9b2061/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/dff101/00000000000000003b9b2061/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "neue-haas-unica";
  src: url("https://use.typekit.net/af/3da9bf/00000000000000003b9b2063/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/3da9bf/00000000000000003b9b2063/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/3da9bf/00000000000000003b9b2063/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

.tk-merriweather {
  font-family: "merriweather", serif;
}
.tk-neue-haas-unica {
  font-family: "neue-haas-unica", sans-serif;
}
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
html {
  font-family: "neue-haas-unica", sans-serif;
  font-size: 100%;
  line-height: 1.625em;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
h1 {
  font-size: 3rem;
  line-height: 3.25rem;
  margin: 1.625rem 0;
}
h2 {
  font-size: 2.25rem;
  line-height: 3.25rem;
  margin: 1.625rem 0;
}
h3 {
  font-size: 1.25rem;
  line-height: 1.625rem;
  margin: 1.625rem 0;
}
h4 {
  font-size: 1rem;
  line-height: 1.625rem;
  margin: 1.625rem 0;
}
h5 {
  font-size: 0.83rem;
  line-height: 1.625rem;
  margin: 1.625rem 0;
}
h6 {
  font-size: 0.67rem;
  line-height: 1.625rem;
  margin: 1.625rem 0;
}
blockquote {
  margin: 1.625rem 40px;
}
dl,
ol,
ul {
  margin: 1.625rem 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
dd {
  margin: 0 0 0 40px;
}
ol,
ul {
  padding: 0 0 0 40px;
}
figcaption,
figure {
  display: block;
}
figure {
  margin: 1.625rem 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
main {
  display: block;
}
p,
pre {
  margin: 1.625rem 0;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
audio,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
  font-family: "neue-haas-unica", sans-serif;
  font-size: 100%;
  line-height: 1.625em;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
input {
  overflow: visible;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  padding: 0;
  color: inherit;
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
details {
  display: block;
}
summary {
  display: list-item;
}
menu {
  display: block;
  margin: 1.625rem 0;
  padding: 0 0 0 40px;
}
menu menu,
ol menu,
ul menu {
  margin: 0;
}
canvas {
  display: inline-block;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}
.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}
.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.col.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.col-xs {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}
.col-xs-1 {
  -ms-flex-preferred-size: 8.33333333%;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}
.col-xs-2 {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}
.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}
.col-xs-4 {
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}
.col-xs-5 {
  -ms-flex-preferred-size: 41.66666667%;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}
.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}
.col-xs-7 {
  -ms-flex-preferred-size: 58.33333333%;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}
.col-xs-8 {
  -ms-flex-preferred-size: 66.66666667%;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}
.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}
.col-xs-10 {
  -ms-flex-preferred-size: 83.33333333%;
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}
.col-xs-11 {
  -ms-flex-preferred-size: 91.66666667%;
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}
.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
.col-xs-offset-0 {
  margin-left: 0;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: start;
}
.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
}
.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.first-xs {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
.last-xs {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
@media only screen and (min-width: 48em) {
  .container {
    width: 49rem;
  }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .col-sm {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .first-sm {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .last-sm {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (min-width: 64em) {
  .container {
    width: 65rem;
  }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .col-md {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .first-md {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .last-md {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (min-width: 75em) {
  .container {
    width: 76rem;
  }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .col-lg {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .first-lg {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .last-lg {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
h1 {
  font-weight: 500;
  margin-top: 0;
  font-size: 2.5rem;
  line-height: 3rem;
  letter-spacing: -0.09rem;
}
@media screen and (min-width: 48em) {
  h1 {
    font-weight: 600;
    font-size: 4rem;
    line-height: 4.5rem;
  }
}
@media screen and (min-width: 64em) {
  h1 {
    font-size: 5rem;
    line-height: 5.625rem;
  }
}
h2 {
  font-weight: 500;
  margin-top: 0;
  font-size: 2.2rem;
  line-height: 2.4rem;
  letter-spacing: -0.09rem;
}
@media screen and (min-width: 48em) {
  h2 {
    font-size: 3rem;
    line-height: 3.3rem;
  }
}
@media screen and (min-width: 64em) {
  h2 {
    font-size: 3.5rem;
    line-height: 4.125rem;
  }
}
h3 {
  font-weight: 500;
  margin-top: 0;
  font-size: 1.875rem;
  line-height: 2.5rem;
}
h4 {
  font-weight: 400;
  font-size: 1.625rem;
  line-height: 2.25rem;
}
body {
  overflow-x: hidden;
  color: #141514;
  margin: 0;
}
p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
p.small {
  font-size: 1rem;
  line-height: 1.625rem;
}
a {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #141514;
  text-decoration: none;
}
a.small {
  font-size: 0.9375rem;
}
a:visited {
  color: #141514;
}
a:hover {
  text-decoration: none;
}
ul,
ol,
li {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 2.375rem;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
a.btn {
  background-color: #141514;
  background: #141514;
  border: 0.125rem solid #141514;
  color: white;
  border-radius: 1px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-decoration: none;
  text-align: center;
  display: inline;
  box-sizing: border-box;
  letter-spacing: -0.5px;
  transition: background-color 0.3s;
  white-space: nowrap;
}
a.btn.btn-sm {
  padding-top: 0.3rem;
  padding-bottom: 0.4rem;
}
a.btn.btn-md {
  padding-top: 1.025rem;
  padding-bottom: 1.1rem;
}
a.btn.btn-lg {
  padding-top: 1.35rem;
  padding-bottom: 1.4rem;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}
a.btn.btn-white {
  color: #141514;
  background: white;
  background-color: white;
  border-color: #141514;
}
a.btn.btn-crazy-hover:hover {
  color: #141514;
  border-color: white;
  animation: crazy 2s infinite;
}
a.btn.btn-crazy {
  color: #141514;
  border-color: white;
  animation: crazy 2s infinite;
}
@keyframes crazy {
  0% {
    background-color: #faecdb;
  }
  30% {
    background-color: #ffe9f0;
  }
  70% {
    background-color: #d6ebe4;
  }
  100% {
    background-color: #faecdb;
  }
}
.container.mobile-adjusted {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media screen and (min-width: 48em) {
  .container.mobile-adjusted {
    padding-left: 0;
    padding-right: 0;
  }
}
.container.page-top {
  margin-top: 6.125rem;
}
@media screen and (min-width: 48em) {
  .container.page-top {
    margin-top: 9.25rem;
  }
}
@media screen and (min-width: 64em) {
  .row.large-gutter {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .row.large-gutter .col-md,
  .row.large-gutter .col-md-1,
  .row.large-gutter .col-md-2,
  .row.large-gutter .col-md-3,
  .row.large-gutter .col-md-4,
  .row.large-gutter .col-md-5,
  .row.large-gutter .col-md-6,
  .row.large-gutter .col-md-7,
  .row.large-gutter .col-md-8,
  .row.large-gutter .col-md-9,
  .row.large-gutter .col-md-10,
  .row.large-gutter .col-md-11,
  .row.large-gutter .col-md-12,
  .row.large-gutter .col-lg,
  .row.large-gutter .col-lg-1,
  .row.large-gutter .col-lg-2,
  .row.large-gutter .col-lg-3,
  .row.large-gutter .col-lg-4,
  .row.large-gutter .col-lg-5,
  .row.large-gutter .col-lg-6,
  .row.large-gutter .col-lg-7,
  .row.large-gutter .col-lg-8,
  .row.large-gutter .col-lg-9,
  .row.large-gutter .col-lg-10,
  .row.large-gutter .col-lg-11,
  .row.large-gutter .col-lg-12 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
.hide-sm {
  display: none !important;
}
@media screen and (min-width: 48em) {
  .hide-sm {
    display: flex !important;
  }
}
@media screen and (min-width: 48em) {
  .hide-sm-up {
    display: none !important;
  }
}
.hide-md {
  display: none !important;
}
@media screen and (min-width: 64em) {
  .hide-md {
    display: block !important;
  }
}
@media screen and (min-width: 64em) {
  .hide-md-up {
    display: none !important;
  }
}
.p-tb-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.p-tb-80 {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 75em) {
  .p-tb-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.m-tb-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.m-tb-80 {
  margin-top: 50px;
  margin-bottom: 50px;
}
@media screen and (min-width: 75em) {
  .m-tb-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
.m-tb-160 {
  margin-top: 60px;
  margin-bottom: 60px;
}
@media screen and (min-width: 75em) {
  .m-tb-160 {
    margin-top: 160px;
    margin-bottom: 160px;
  }
}
#header {
  height: 3.125rem;
  padding-left: 0.5rem;
  padding-right: 1rem;
  margin: 0;
}
@media screen and (min-width: 48em) {
  #header {
    height: 5.25rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}
#header .logo {
  display: block;
}
#header .logo svg {
  width: 1.25rem;
  height: 1.25rem;
  z-index: 12;
  fill: #141514;
  position: relative;
  display: block;
}
#header button {
  outline: none !important;
  z-index: 12;
  position: relative;
  display: block;
  margin-left: auto;
}
#header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
}
#header ul.right {
  margin-left: auto;
}
#header li {
  float: left;
  padding-right: 3rem;
  cursor: pointer;
}
#header li:last-child {
  padding-right: 0;
}
#header li.cta {
  line-height: 2.375rem;
}
#header li a:not(.btn) {
  text-align: left;
  font-size: 1rem;
  line-height: 2.375rem;
  color: #141514;
  text-decoration: none;
}
#header li.has-submenu ul {
  display: none;
  position: absolute;
  top: 80%;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
  padding: 0px 20px;
  width: 14rem;
  z-index: 5;
  background: white;
}
#header li.has-submenu li {
  float: none;
  padding-left: 0.625rem;
  border-bottom: 1px solid #dfdfdf;
}
#header li.has-submenu li a {
  line-height: 4.5rem;
}
#header li.has-submenu li:last-child {
  border: none;
}
#header li.has-submenu:hover ul {
  display: block;
}
#header .col-xs-7 li {
  line-height: 3.125rem;
}
#menu-overlay {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #141514;
  display: none;
  opacity: 0;
  transition: opacity 0.2s;
}
#menu-overlay ul {
  margin-top: 100px;
  padding-left: 30px;
  list-style: none;
}
#menu-overlay ul li {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}
@media screen and (max-height: 560px) {
  #menu-overlay ul li {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
}
#menu-overlay ul li a {
  color: white !important;
  text-decoration: none;
  font-size: 1.625rem;
  line-height: 2.375rem;
  display: block;
  box-sizing: border-box;
}
#menu-overlay ul li.has-submenu {
  margin-bottom: 0;
}
#menu-overlay ul li.has-submenu a {
  font-weight: bold;
}
#menu-overlay ul li.is-submenu {
  margin-top: 0;
  margin-bottom: 0;
}
#menu-overlay div.cta {
  position: absolute;
  bottom: 10px;
  left: 30px;
  width: calc(100% - 60px);
  margin: auto;
}
#menu-overlay div.cta a {
  display: block;
  border-color: #141514;
}
#menu-overlay div.cta a span {
  font-weight: bold;
}
.with-menu {
  overflow-y: hidden;
}
.with-menu #menu-overlay {
  display: block;
  opacity: 1;
}
.with-menu #header .logo svg {
  fill: white;
}
.with-menu #header .hamburger-inner,
.with-menu #header .hamburger-inner::before,
.with-menu #header .hamburger-inner::after {
  background-color: white;
}
.with-menu #header-container {
  z-index: 12;
  background: #141514;
}
#header-container {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 10;
}
footer {
  background: #141514;
  padding-top: 40px;
  padding-bottom: 25px;
}
footer .row:first-child {
  margin-bottom: 60px;
}
footer p {
  color: white;
  margin-bottom: 2rem;
  font-weight: bold;
}
footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
footer a {
  color: rgba(255, 255, 255, 0.5) !important;
}
footer .row.bottom a,
footer .row.bottom span {
  font-size: 0.6875rem;
  color: rgba(255, 255, 255, 0.5);
}
footer .row.bottom a {
  text-decoration: underline;
}
footer .row.bottom span.made {
  color: white;
}
#home-faq h2,
#pricing-faq h2,
#features-faq h2 {
  padding-bottom: 2rem;
  border-bottom: 1px solid #141514;
  box-sizing: border-box;
}
#home-faq h3,
#pricing-faq h3,
#features-faq h3 {
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-top: 1.625rem;
}
#home-faq .question,
#pricing-faq .question,
#features-faq .question {
  font-weight: bold;
  margin-bottom: 0.5rem;
  max-width: 25rem;
}
#home-faq .small,
#pricing-faq .small,
#features-faq .small {
  color: rgba(0, 0, 0, 0.5);
  margin-top: 1rem;
  max-width: 25rem;
}
#hero {
  padding-top: 6.25rem;
  padding-bottom: 0.5rem;
  text-align: center;
}
@media screen and (min-width: 48em) {
  #hero {
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
}
@media screen and (min-width: 64em) {
  #hero {
    padding-top: calc(50vh - 15rem);
    padding-bottom: calc(50vh - 24rem);
  }
}
#hero h4 {
  width: 100%;
  margin-bottom: 4rem;
}
@media screen and (min-width: 48em) {
  #hero h4 {
    max-width: 34rem;
    margin: auto;
    margin-bottom: 5rem;
  }
}
#hero .cta a {
  display: block;
  margin: 0 auto 20px;
  width: 100%;
}
@media screen and (min-width: 31.25rem) {
  #hero .cta a {
    width: 16.75rem;
  }
}
@media screen and (min-width: 48em) {
  #hero .cta a {
    display: inline-block;
    margin: 0;
  }
  #hero .cta a:first-child {
    margin-right: 20px;
  }
}
@media screen and (max-width: 47.933em) {
  #hero .cta a#hero-cta {
    color: black;
    border: none;
    animation: crazy 2s infinite;
  }
}
#hero .cta a span {
  font-weight: bold;
}
#clients .row {
  margin: 0;
}
@media screen and (max-width: 63.933em) {
  #clients {
    max-width: 34rem;
  }
}
@media screen and (max-width: 63.933em) {
  #clients .icons {
    flex-basis: 33.33333%;
  }
  #clients .icons:nth-child(3n + 2) {
    text-align: center;
  }
  #clients .icons:nth-child(3n) {
    text-align: right;
  }
  #clients .icons svg {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 47.933em) {
  #clients .icons svg {
    max-width: 70%;
    max-height: 2.5rem;
  }
}
#clients svg {
  fill: #141514;
}
.home-features {
  padding: 30px 25px;
  box-sizing: border-box;
  outline: none !important;
}
@media screen and (min-width: 25em) {
  .home-features {
    padding: 50px 35px;
  }
}
@media screen and (min-width: 48em) {
  .home-features {
    padding: 58px 51px;
  }
}
@media screen and (max-width: 47.933em) {
  .home-features {
    margin: 0 !important;
  }
}
.home-features#search {
  background-color: #faecdb;
}
.home-features#pick {
  background-color: #ffe9f0;
}
.home-features#export {
  background-color: #d6ebe4;
}
.home-features .left-col {
  position: relative;
}
@media screen and (min-width: 64em) {
  .home-features p {
    margin-bottom: 4rem;
  }
}
.home-features p span {
  font-weight: bold;
}
.home-features a.learn-more {
  font-weight: bold;
  display: block;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #141514;
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 64em) {
  .home-features a.learn-more {
    margin-bottom: 4rem;
  }
}
.home-features a.question {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
}
@media screen and (min-width: 64em) {
  .home-features a.question {
    position: absolute;
    bottom: 0;
    left: 0.5rem;
  }
}
.home-features .icon-ctn {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}
@media screen and (min-width: 64em) {
  .home-features .icon-ctn {
    margin-bottom: 0;
  }
}
.home-features .icon-ctn video {
  width: 100% !important;
  height: auto !important;
}
#extras .icon-ctn {
  position: relative;
  width: 4.6875rem;
  height: 4.6875rem;
}
#extras h3 {
  margin-top: 20px;
}
#what-we-do {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media screen and (min-width: 64em) {
  #what-we-do {
    padding-left: 0;
    padding-right: 0;
  }
}
#what-we-do .col1 {
  position: relative;
}
@media screen and (min-width: 64em) {
  #what-we-do .col1 .bottom-text {
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    bottom: 0;
  }
}
#what-we-do .col1 .bottom-text p {
  color: rgba(0, 0, 0, 0.5);
}
#what-we-do .col1 .bottom-text p:last-child {
  margin: 0;
}
#what-we-do .col2 {
  margin-bottom: 0.5rem;
}
@media screen and (min-width: 64em) {
  #what-we-do .col2 {
    margin-bottom: 0;
  }
}
#what-we-do .col2 .color-col {
  background-color: #d6ebe4;
}
#what-we-do .col3 .color-col {
  background-color: #ffe9f0;
}
#what-we-do .color-col {
  padding: 30px 25px;
  height: 100%;
  box-sizing: border-box;
}
@media screen and (min-width: 64em) {
  #what-we-do .color-col {
    padding: 45px;
  }
}
#what-we-do .color-col h3 {
  position: relative;
  margin-bottom: 5rem;
}
@media screen and (min-width: 64em) {
  #what-we-do .color-col h3 {
    margin-bottom: 6rem;
  }
}
#what-we-do .color-col h3:after {
  content: "";
  width: 60px;
  position: absolute;
  left: 0;
  border: 1px solid black;
  bottom: -2rem;
}
@media screen and (min-width: 64em) {
  #what-we-do .color-col h3:after {
    bottom: -3rem;
  }
}
#what-we-do .color-col p.small {
  margin-top: 0.3rem;
  margin-bottom: 0.7rem;
  color: rgba(0, 0, 0, 0.5);
}
#what-we-do .color-col p.features {
  margin-left: 20px;
  position: relative;
}
#what-we-do .color-col p.features svg {
  position: absolute;
  left: -22px;
  top: 0.5rem;
}
#start-now .col1,
#cta-affiliate .col1 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
#start-now h4,
#cta-affiliate h4 {
  margin-bottom: 3rem;
}
@media screen and (min-width: 64em) {
  #start-now h4,
  #cta-affiliate h4 {
    margin-bottom: 3.5rem;
  }
}
#start-now p.signin,
#cta-affiliate p.signin {
  margin-top: 2.5rem;
  font-size: 0.6875rem;
}
#start-now p.signin a,
#cta-affiliate p.signin a {
  font-size: inherit;
  text-decoration: underline;
}
#start-now .icon-ctn,
#cta-affiliate .icon-ctn {
  background: #faecdb;
  width: 100%;
  height: 100%;
}
#start-now .btn span,
#cta-affiliate .btn span {
  font-weight: bold;
}
.top-link-list {
  text-align: center;
}
.top-link-list a {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);
}
.top-link-list a:hover {
  text-decoration: underline;
}
.top-link-list ul {
  margin: 0 0 0 20px;
  padding-left: 0;
  list-style: none;
}
@media screen and (min-width: 25em) {
  .top-link-list ul {
    list-style: disc;
  }
}
.top-link-list li {
  text-align: center;
}
@media screen and (min-width: 25em) {
  .top-link-list li {
    text-align: left;
  }
}
.top-link-list .row {
  margin-bottom: 50px;
}
@media screen and (max-width: 63.933em) {
  .top-link-list .row > div:nth-child(2) {
    order: 3;
  }
  .top-link-list .row > div:nth-child(3) {
    order: 2;
  }
}
@media screen and (max-width: 24.933em) {
  .top-link-list .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .top-link-list .row > div:nth-child(2) {
    order: 2;
  }
  .top-link-list .row > div:nth-child(3) {
    order: 3;
  }
}
#top-profiles-header {
  text-align: center;
}
#top-profiles-header h1 {
  font-size: 2.2rem;
  line-height: 2.4rem;
}
#top-profiles-header p {
  text-align: center;
  margin: 0 auto 4rem;
}
#top-profiles-list {
  margin-top: 80px;
  padding-bottom: 60px;
}
#top-profiles-list .row > div {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
}
#top-profiles-list .row.ctn {
  border-bottom: 1px solid #ebebeb;
  padding-top: 20px;
  padding-bottom: 20px;
}
#top-profiles-list .row.ctn:last-of-type {
  border: none;
}
#top-profiles-list .col-1 {
  flex: 0 0 10%;
  max-width: 10%;
}
@media screen and (min-width: 48em) {
  #top-profiles-list .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}
#top-profiles-list .col-1 span {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}
#top-profiles-list .col-2 {
  flex: 0 0 90%;
  max-width: 90%;
}
@media screen and (min-width: 48em) {
  #top-profiles-list .col-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
#top-profiles-list .col-2 img {
  width: 100px;
  height: auto;
  border-radius: 50%;
  margin-left: calc(40% - 35px);
  padding-bottom: 20px;
}
@media screen and (min-width: 48em) {
  #top-profiles-list .col-2 img {
    margin-left: 0;
    padding-bottom: 0;
  }
}
#top-profiles-list .col-3 {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
}
@media screen and (min-width: 48em) {
  #top-profiles-list .col-3 {
    flex: 0 0 71.66667%;
    max-width: 71.66667%;
    text-align: left;
  }
}
#top-profiles-list .col-3 h4 {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  line-height: 1.1;
}
#top-profiles-list .col-3 span {
  color: rgba(0, 0, 0, 0.5);
}
#top-profiles-list .col-3 span.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}
#top-profiles-list .col-3 p {
  margin-top: 0.5rem;
  text-align: center;
}
#top-profiles-list .col-3 p svg {
  margin-right: 0.5rem;
}
@media screen and (min-width: 48em) {
  #top-profiles-list .col-3 p {
    text-align: left;
  }
}
#top-profiles-list .col-4 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-bottom: 20px;
}
@media screen and (min-width: 48em) {
  #top-profiles-list .col-4 {
    flex: 0 0 34%;
    max-width: 34%;
    padding-bottom: 0;
  }
}
#top-profiles-list .col-5 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
@media screen and (min-width: 48em) {
  #top-profiles-list .col-5 {
    flex: 0 0 22%;
    max-width: 22%;
  }
}
#top-profiles-list .col-5.stats {
  margin-top: 0.1rem;
}
@media screen and (max-width: 24.933em) {
  #top-profiles-list .col-5.stats {
    line-height: 1.2;
  }
}
#top-profiles-list .col-5.stats p {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}
@media screen and (max-width: 24.933em) {
  #top-profiles-list .col-5.stats span {
    font-size: 0.75rem;
  }
}
#top-profiles-list .col-xs-12 p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.9rem;
  line-height: 1.2;
  margin: 10px 0;
}
#top-profiles-list .col-xs-12 strong {
  margin-right: 5px;
}
.container.top-link-list,
.container#top-profiles-list,
.container#top-profiles-header {
  max-width: 960px;
}
@media screen and (min-width: tablet) {
  .container.top-link-list,
  .container#top-profiles-list,
  .container#top-profiles-header {
    max-width: 720px;
  }
}
@media screen and (min-width: phone) {
  .container.top-link-list,
  .container#top-profiles-list,
  .container#top-profiles-header {
    max-width: 540px;
  }
}
#top-bottom-cta {
  text-align: center;
  margin-bottom: 1rem;
}
@media screen and (min-width: 48em) {
  #top-bottom-cta {
    margin-bottom: 2rem;
  }
}
#top-bottom-cta h2 {
  font-size: 2.5rem;
  line-height: 3.875rem;
  margin-top: 0;
  margin-bottom: 1rem;
}
#top-bottom-cta h4 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  margin: 1rem auto 2rem;
  max-width: 500px;
}
#top-bottom-cta .btn {
  display: inline-block;
  color: white;
}
#top-bottom-cta span {
  font-weight: bold;
}
#top-bottom-cta .col-xs-12 {
  padding: 1.5rem 1.5rem 2.5rem;
  background-color: #d6ebe4;
}
@media screen and (min-width: 48em) {
  #top-bottom-cta .col-xs-12 {
    padding: 5rem;
  }
}
#top-popup.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  display: table;
  transition: opacity 0.3s ease;
}
#top-popup .modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
#top-popup .modal-container {
  width: calc(100% - 40px);
  margin: 0px auto;
  background-color: #fff;
  transition: all 0.3s ease;
  position: relative;
  border-radius: 5px;
}
@media screen and (min-width: 880px) {
  #top-popup .modal-container {
    width: 800px;
  }
}
#top-popup .row {
  padding: 40px;
  height: 100%;
}
#top-popup .close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 2;
}
#top-popup .close svg {
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 8px;
  vertical-align: middle;
  stroke: #141514 !important;
}
#top-popup h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 2.5rem;
  letter-spacing: -0.3px;
  color: #141514;
  max-width: 35rem;
}
@media screen and (min-width: 800px) {
  #top-popup h6 {
    max-width: 25rem;
  }
}
#top-popup p {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #141514;
  margin-bottom: 35px;
  width: 100%;
}
@media screen and (min-width: 800px) {
  #top-popup p {
    max-width: 25rem;
  }
}
#top-popup .badge {
  display: inline-block;
  border-radius: 10px;
  padding: 0.125rem 0.375rem;
  font-size: 0.6875rem;
  line-height: 1rem;
  background-color: #d6ebe4;
  text-transform: uppercase;
}
#top-popup input {
  background: rgba(199, 199, 199, 0.25);
  width: 100%;
  border: none;
  outline: none;
  height: 60px;
  padding: 5px 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
@media screen and (min-width: 500px) {
  #top-popup input {
    width: 250px;
    margin-bottom: 0px;
  }
}
#top-popup button {
  background-color: #141514;
  color: white;
  height: 60px;
  border: none;
  padding: 5px 20px;
  cursor: pointer;
}
#top-popup .error {
  color: red;
  margin: 0;
  display: none;
}
#top-popup .green-section {
  background-color: #d6ebe4;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 240px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
}
@media screen and (min-width: 800px) {
  #top-popup .green-section {
    display: block;
  }
}
#top-popup #book {
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
  height: 290px;
  display: none;
}
#top-popup #book #check {
  display: none;
}
@media screen and (min-width: 800px) {
  #top-popup #book {
    display: block;
  }
}
#top-popup.completed #book #check {
  display: block;
}
#teaser-trial {
  margin-top: 3.125rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background: #faecdb;
}
@media screen and (min-width: 48em) {
  #teaser-trial {
    margin-top: 5.25rem;
  }
}
@media screen and (min-width: 64em) {
  #teaser-trial {
    padding-top: 6.75rem;
    padding-bottom: 6.75rem;
  }
}
#teaser-trial p.description {
  margin-bottom: 4rem;
}
#teaser-trial p.features {
  margin-left: 30px;
  position: relative;
}
#teaser-trial p.features svg {
  position: absolute;
  left: -32px;
  top: 0.5rem;
}
#teaser-trial p.features span {
  font-weight: bold;
}
#teaser-trial .btn {
  margin-bottom: 3rem;
  display: block;
}
@media screen and (min-width: 64em) {
  #teaser-trial .btn {
    margin-bottom: 0;
    display: inline;
  }
}
#teaser-trial .btn span {
  font-weight: bold;
}
#pricing {
  margin-top: 40px;
}
@media screen and (min-width: 48em) {
  #pricing {
    margin-top: 70px;
  }
}
@media screen and (min-width: 64em) {
  #pricing {
    margin-top: 160px;
  }
}
#pricing .pricing-header {
  text-align: left;
  margin-bottom: 20px;
  margin-top: 30px;
}
@media screen and (min-width: 64em) {
  #pricing .pricing-header {
    margin-top: 0;
    text-align: center;
    margin-bottom: 100px;
  }
}
#pricing .pricing-schedule > span {
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  vertical-align: middle;
}
#pricing .pricing-schedule > span:first-child {
  margin-right: 2rem;
}
#pricing .pricing-schedule > span span {
  vertical-align: middle;
}
#pricing .pricing-schedule > span span.deal {
  margin-left: 5px;
  font-size: 0.6875rem;
  line-height: 1rem;
  color: #141514;
  animation: crazy 2s infinite;
  border-radius: 10px;
  padding: 0.1rem 0.3rem;
}
#pricing #pricing-row.monthly #monthly-schedule {
  font-weight: bold;
  text-decoration: underline;
}
#pricing #pricing-row.monthly .plan .monthly-price {
  display: inline-block;
}
#pricing #pricing-row.yearly #yearly-schedule span:not(.deal) {
  font-weight: bold;
  text-decoration: underline;
}
#pricing #pricing-row.yearly .plan .yearly-price {
  display: inline-block;
}
#pricing #pricing-row .currency {
  font-size: 0.6em;
}
#pricing .col-md-4 {
  margin-bottom: 1rem;
}
@media screen and (min-width: 75em) {
  #pricing .col-md-4 {
    margin-bottom: 0;
  }
}
#pricing .plan {
  padding: 1.5rem;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#pricing .plan#lite {
  background: #ffe9f0;
}
#pricing .plan#lite a.btn {
  background: #e7aec0;
  background-color: #e7aec0;
  border-color: #e7aec0;
}
#pricing .plan#brand {
  background: #faecdb;
}
#pricing .plan#brand a.btn {
  background: #d0b594;
  background-color: #d0b594;
  border-color: #d0b594;
}
#pricing .plan#agency {
  background: #d6ebe4;
}
#pricing .plan#agency a.btn {
  background: #95beb0;
  background-color: #95beb0;
  border-color: #95beb0;
}
#pricing .plan#business {
  background: #f8f8f8;
}
#pricing .plan#business a.btn {
  border: none !important;
  padding-top: 1.15rem;
  padding-bottom: 1.225rem;
}
#pricing .plan h2 {
  font-weight: 400;
}
#pricing .plan .monthly-price,
#pricing .plan .yearly-price {
  font-weight: 500;
  display: none;
}
#pricing .plan p.title {
  margin: 0;
}
#pricing .plan p.description {
  color: rgba(0, 0, 0, 0.5);
}
#pricing .plan p.description span {
  font-weight: bold;
}
#pricing .plan a.btn {
  display: block;
  margin-top: 3rem;
  font-weight: bold;
}
#pricing .plan div.features {
  border-top: 1px solid #141514;
  padding-top: 1rem;
}
#pricing .plan div.features p {
  margin-left: 5px;
  position: relative;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#pricing .plan div.features p svg.icon--check,
#pricing .plan div.features p svg.icon--close {
  position: absolute;
  left: -32px;
  top: 0.5rem;
}
#pricing .plan div.features p svg.icon--info {
  position: absolute;
  right: 0;
  top: 0.5rem;
  opacity: 0.25;
  transition: opacity 0.2s;
}
#pricing .plan div.features p .small {
  font-size: 0.6em;
}
#pricing .plan div.features p .tooltip {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  background: #141514;
  color: white;
  padding: 1rem;
  display: none;
  font-size: 0.7em;
  line-height: 1.2;
  font-weight: 400;
  z-index: 1;
}
#pricing .plan div.features p .tooltip:before {
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  top: -5px;
  left: 6rem;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent black transparent;
  box-sizing: border-box;
}
#pricing .plan div.features p.not {
  color: rgba(0, 0, 0, 0.25);
}
#pricing .plan div.features p:last-child {
  margin-bottom: 0;
}
#pricing .plan div.features p:hover:not(.not) svg.icon--info {
  opacity: 1;
}
#pricing .plan div.features p:hover:not(.not) .tooltip {
  display: block;
}
#pricing .plan div.features p.empty {
  display: none;
}
@media screen and (min-width: 48em) {
  #pricing .plan div.features p.empty {
    display: block;
  }
}
#included h4,
#how-it-works-affiliate h4,
#how-it-works-features h4 {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1rem;
}
#included p,
#how-it-works-affiliate p,
#how-it-works-features p {
  max-width: 17.5rem;
  margin-top: 0.7rem;
}
#included p.title,
#how-it-works-affiliate p.title,
#how-it-works-features p.title {
  font-weight: bold;
  margin-bottom: 1rem;
}
#included p.small,
#how-it-works-affiliate p.small,
#how-it-works-features p.small {
  margin-bottom: 3rem;
}
#included p.small:last-child,
#how-it-works-affiliate p.small:last-child,
#how-it-works-features p.small:last-child {
  margin-bottom: 0;
}
#included p.small.subtitle,
#how-it-works-affiliate p.small.subtitle,
#how-it-works-features p.small.subtitle {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
}
#included p.small.subtitle a,
#how-it-works-affiliate p.small.subtitle a,
#how-it-works-features p.small.subtitle a {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
}
#section-normal {
  margin-bottom: 3rem;
  min-height: calc(100vh - 34rem);
  box-sizing: border-box;
}
#section-normal h3 {
  margin-bottom: 20px;
  margin-top: 30px;
}
#section-normal a {
  font-size: inherit;
  text-decoration: underline;
}
#section-normal p {
  margin-bottom: 10px;
}
#section-normal ul {
  margin-top: 20px;
  margin-bottom: 20px;
  list-style: disc;
  padding-left: 20px;
}
#section-normal ul li {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
#featured-posts {
  margin-bottom: 1rem;
}
#featured-posts.categories h1 {
  margin-bottom: 2.5rem;
}
#featured-posts h1 {
  font-weight: 500;
  margin-top: 0;
  font-size: 2.2rem;
  line-height: 2.4rem;
  letter-spacing: -0.09rem;
}
@media screen and (min-width: 48em) {
  #featured-posts h1 {
    font-size: 3rem;
    line-height: 3.3rem;
  }
}
@media screen and (min-width: 48em) {
  #featured-posts h1 {
    font-size: 3.5rem;
    line-height: 3.875rem;
  }
}
#featured-posts .post {
  margin-bottom: 2rem;
}
@media screen and (min-width: 64em) {
  #featured-posts .post {
    margin-bottom: 5rem;
  }
}
#featured-posts .post .img-link {
  display: block;
}
#featured-posts .post img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
  display: block;
}
#featured-posts .post h4 {
  font-weight: bold;
  margin-top: 1rem;
}
#featured-posts .post h4 a {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}
#featured-posts .post p {
  margin: 1rem 0;
}
#featured-posts .post .author {
  font-size: 0.6875rem;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.5);
}
#featured-posts .post .author span {
  font-weight: bold;
}
#featured-posts .post .category {
  display: inline-block;
  border-radius: 10px;
  padding: 0.125rem 0.375rem;
  font-size: 0.6875rem;
  line-height: 1rem;
}
#featured-posts .post.tips .category {
  background-color: #ffe9f0;
}
#featured-posts .post.studies .category,
#featured-posts .post.guides .category,
#featured-posts .post.ambassador-marketing .category {
  background-color: #d6ebe4;
}
#featured-posts .post.tutorials .category,
#featured-posts .post.wikis .category {
  background-color: #faecdb;
}
#featured-posts .post.podcasts .category {
  background-color: #141514;
  color: white;
}
.posts-list-title p {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 1rem;
}
.post-list {
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 64em) {
  .post-list {
    margin-bottom: 5rem;
  }
}
.post-list .col-sm-6 {
  margin-bottom: 1rem;
}
.post-list .post {
  padding: 1rem;
  position: relative;
  height: 100%;
  box-sizing: border-box;
}
.post-list .post .title {
  margin-top: 0;
  margin-bottom: 3rem;
  font-weight: bold;
}
.post-list .post .title a {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}
.post-list .post .author {
  margin: 0;
  font-size: 0.6875rem;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
.post-list .post .author span {
  font-weight: bold;
}
.post-list.tips .post {
  background-color: #ffe9f0;
}
.post-list.studies .post,
.post-list.guides .post,
.post-list.ambassador-marketing .post {
  background-color: #d6ebe4;
}
.post-list.tutorials .post,
.post-list.wikis .post {
  background-color: #faecdb;
}
#top-affiliate {
  margin-top: 3.125rem;
  padding-top: 4rem;
  padding-bottom: 3.2rem;
  background: #d6ebe4;
}
@media screen and (min-width: 48em) {
  #top-affiliate {
    margin-top: 5.25rem;
    padding-bottom: 4rem;
  }
}
@media screen and (min-width: 64em) {
  #top-affiliate {
    padding-top: 6.75rem;
    padding-bottom: 6.75rem;
  }
}
#top-affiliate p.description {
  margin-bottom: 4rem;
}
#top-affiliate p.description span {
  font-weight: bold;
}
#top-affiliate .features {
  margin-top: 2rem;
}
#top-affiliate .features span {
  margin-left: 0.75rem;
  margin-right: 2rem;
  position: relative;
  display: inline-block;
  font-size: 0.6875rem;
  line-height: 1rem;
}
#top-affiliate .features span svg {
  position: absolute;
  left: -0.75rem;
  top: 0.3rem;
  width: 0.5rem;
  height: 0.4375rem;
}
#top-affiliate .btn {
  margin-bottom: 3rem;
  font-weight: bold;
}
@media screen and (min-width: 64em) {
  #top-affiliate .btn {
    margin-bottom: 0;
    display: inline;
  }
}
#calculator {
  background-color: #141514;
  height: 100%;
  padding: 1.5rem 2rem;
  color: white;
  position: relative;
  box-sizing: border-box;
  margin-top: 2rem;
}
@media screen and (min-width: 64em) {
  #calculator {
    margin-top: 0;
  }
}
#calculator p {
  max-width: 18rem;
  margin-bottom: 2rem;
}
#calculator .slider-ctn {
  position: relative;
  margin-bottom: 2rem;
  width: calc(100% - 1rem);
  margin-left: 0.5rem;
  padding-top: 0.75rem;
}
#calculator .slider-ctn #customer-nb {
  position: absolute;
  top: 0;
  left: 2.5%;
  font-size: 0.6875rem;
  line-height: 1rem;
}
#calculator .placed {
  position: absolute;
  font-size: 1rem;
  line-height: 1.625rem;
  color: rgba(255, 255, 255, 0.5);
}
#calculator .placed.top {
  top: 1.5rem;
  right: 2rem;
  display: none;
}
@media screen and (min-width: 48em) {
  #calculator .placed.top {
    display: block;
  }
}
#calculator .placed.bottom {
  bottom: 1.5rem;
  left: 2rem;
}
#calculator input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
  padding-bottom: 2px;
}
#calculator input[type="range"]:focus {
  outline: none;
}
#calculator input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #141514, 0px 0px 1px #141514;
  background: white;
  border-radius: 1.3px;
  border: 0.2px solid #141514;
}
#calculator input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #141514, 0px 0px 1px #141514;
  border: 1px solid #141514;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
#calculator input[type="range"]:focus::-webkit-slider-runnable-track {
  background: white;
}
#calculator input[type="range"]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #141514, 0px 0px 1px #141514;
  background: white;
  border-radius: 1.3px;
  border: 0.2px solid #141514;
}
#calculator input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #141514, 0px 0px 1px #141514;
  border: 1px solid #141514;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}
#calculator input[type="range"]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
#calculator input[type="range"]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #141514;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #141514, 0px 0px 1px #141514;
}
#calculator input[type="range"]::-ms-fill-upper {
  background: white;
  border: 0.2px solid #141514;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #141514, 0px 0px 1px #141514;
}
#calculator input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #141514, 0px 0px 1px #141514;
  border: 1px solid #141514;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}
#calculator input[type="range"]:focus::-ms-fill-lower {
  background: white;
}
#calculator input[type="range"]:focus::-ms-fill-upper {
  background: white;
}
#cta-affiliate,
#cta-features {
  position: relative;
}
@media screen and (min-width: 75em) {
  #cta-affiliate:before,
  #cta-features:before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: #cdcdcd;
    top: calc(-30px + 1rem);
  }
}
@media screen and (min-width: 75em) and (min-width: 75em) {
  #cta-affiliate:before,
  #cta-features:before {
    top: -80px;
  }
}
#cta-affiliate br,
#cta-features br {
  display: none;
}
@media screen and (min-width: 64em) {
  #cta-affiliate br,
  #cta-features br {
    display: block;
  }
}
#cta-affiliate h4,
#cta-features h4 {
  margin-bottom: 3rem;
}
#cta-affiliate a span,
#cta-features a span {
  font-weight: bold;
}
#features-body > section:nth-child(3n + 1) {
  background-color: #faecdb;
}
#features-body > section:nth-child(3n + 2) {
  background-color: #d6ebe4;
}
#features-body > section:nth-child(3n) {
  background-color: #ffe9f0;
}
@media screen and (min-width: 64em) {
  #features-top h1 {
    font-size: 4rem;
    line-height: 4.5rem;
  }
}
#how-it-works-features {
  border-top: 1px solid #cdcdcd;
  margin-top: 30px;
  padding-top: 30px;
}
@media screen and (min-width: 75em) {
  #how-it-works-features {
    margin-top: 80px;
    padding-top: 80px;
  }
}
.features-section {
  padding: 30px 25px;
  box-sizing: border-box;
  outline: none !important;
}
@media screen and (min-width: 25em) {
  .features-section {
    padding: 50px 35px;
  }
}
@media screen and (min-width: 48em) {
  .features-section {
    padding: 58px 51px;
  }
}
@media screen and (max-width: 47.933em) {
  .features-section {
    margin: 0 !important;
  }
}
.features-section .left-col {
  position: relative;
}
.features-section p {
  margin-top: 0;
}
@media screen and (min-width: 64em) {
  .features-section .description {
    margin-bottom: 4rem;
  }
}
.features-section .description span {
  font-weight: bold;
}
.features-section .description a {
  font-size: inherit;
  line-height: inherit;
  text-decoration: underline;
}
.features-section .points-title {
  font-weight: bold;
  margin-bottom: 1rem;
}
.features-section .small {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.features-section .small:last-child {
  margin-bottom: 0;
}
.features-section .small svg {
  height: 0.625rem;
  margin-right: 10px;
}
.features-section a.learn-more {
  font-weight: bold;
  display: block;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #141514;
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 64em) {
  .features-section a.learn-more {
    margin-bottom: 4rem;
  }
}
.features-section h2 {
  margin-top: 30px;
}
.features-section img {
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
}
.features-section .col-md-7 .col-sm-4 {
  margin-bottom: 20px;
}
@media screen and (min-width: 48em) {
  .features-section .col-md-7 .col-sm-4 {
    margin-bottom: 0;
  }
}
#engagement-header {
  text-align: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 48em) {
  #engagement-header h1 {
    font-size: 4rem;
    line-height: 4.6rem;
  }
}
@media screen and (max-width: 47.933em) {
  #engagement-header p br {
    display: none;
  }
}
#engagement-form {
  max-width: 700px;
  margin: 50px auto 60px;
  text-align: center;
}
#engagement-form input {
  background: transparent;
  width: 90%;
  max-width: 350px;
  border: 1px solid #000000;
  outline: none;
  height: 60px;
  padding: 5px 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
@media screen and (min-width: 700px) {
  #engagement-form input {
    margin-bottom: 0px;
    margin-right: 10px;
  }
}
#engagement-form button {
  background-color: #000000;
  color: white;
  height: 60px;
  border: none;
  padding: 5px 0px;
  width: 150px;
  cursor: pointer;
  border-radius: 0;
  outline: none !important;
}
#engagement-calculator .card {
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  padding: 20px 15px;
}
@media screen and (min-width: 48em) {
  #engagement-calculator .card {
    padding: 20px 25px;
  }
}
#engagement-calculator .error {
  text-align: center;
  color: red;
  margin-top: -20px;
  margin-bottom: 60px;
}
#engagement-calculator .maintenance {
  text-align: center;
  color: red;
  margin-top: 40px;
  margin-bottom: 60px;
}
#engagement-result {
  margin-bottom: 60px;
}
@media screen and (min-width: 48em) {
  #engagement-result > div {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
#engagement-result .profile-info {
  margin-top: 40px;
  text-align: center;
}
#engagement-result .profile-info img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  cursor: pointer;
}
#engagement-result .profile-info .username {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 10px;
  cursor: pointer;
}
#engagement-result .profile-info span {
  font-size: 14px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
}
#engagement-result .profile-info .stats {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  padding: 25px;
  margin: 60px 0 45px;
}
#engagement-result .profile-info .stats p {
  margin: 0;
  font-weight: 500;
  line-height: 1;
}
#engagement-result .profile-info a {
  width: 100%;
  font-weight: 700;
  display: block;
  margin-bottom: 40px;
}
@media screen and (min-width: 64em) {
  #engagement-result .profile-info a {
    margin-bottom: 0;
  }
}
#engagement-result .posts-info {
  text-align: center;
}
#engagement-result .posts-info > .row > div {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#engagement-result .posts-info .stats p {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 1;
}
#engagement-result .posts-info .stats span {
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  display: block;
  margin-top: 5px;
}
#engagement-result .posts-info .stats .card {
  height: 100%;
}
#engagement-result .posts-info .stats.likes svg {
  fill: #e7aec0;
}
#engagement-result .posts-info .stats.engagement svg {
  fill: #95beb0;
}
#engagement-result .posts-info .stats.comments svg {
  fill: #d0b594;
}
#engagement-result .posts-info .graph {
  text-align: left;
}
#engagement-result .posts-info .graph p {
  font-weight: 700;
  margin-top: 0;
}
#engagement-result .posts-info .graph .curve-inbeat .stroke {
  stroke: #e7aec0;
  stroke-width: 2;
}
#engagement-result .posts-info .graph .curve-inbeat .fill {
  fill: url(#graphFill)!important;
  fill-opacity: 0.5!important;
}
#engagement-result .posts-info .graph .label {
  font-size: 12px;
}
#engagement-result .posts-info .posts {
  text-align: left;
}
#engagement-result .posts-info .posts .card-title {
  font-weight: 700;
  margin-top: 0;
}
#engagement-result .posts-info .posts .post {
  border: 1px solid #ebebeb;
  border-radius: 2px;
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
}
#engagement-result .posts-info .posts .post .img {
  padding-top: 100%;
  background-size: cover;
  position: relative;
  background-position: center;
}
#engagement-result .posts-info .posts .post .img .views {
  position: absolute;
  bottom: 2px;
  left: 10px;
  color: white;
  font-size: 12px;
}
#engagement-result .posts-info .posts .post .img .views svg {
  width: 10px;
  height: 10px;
  vertical-align: middle;
  margin-right: 3px;
}
#engagement-result .posts-info .posts .post p {
  font-size: 12px;
  line-height: 18px;
  margin: 7px 0;
}
#engagement-result .posts-info .posts .post p svg {
  width: 8px;
  height: 8px;
  vertical-align: middle;
  margin: 0 2px 0;
  fill: #d6d6d6 !important;
}
#engagement-result .posts-info .posts .post p span {
  display: inline-block;
}
#engagement-result .posts-info .posts .post p span:first-child svg {
  margin-left: 0;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 19px;
  height: 19px;
  margin: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.mip#hero {
  padding-bottom: 0;
  margin-bottom: 14rem;
}
.mip#hero h4 {
  max-width: none;
  margin-bottom: 2.5rem;
}
.mip#brands {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6.5rem;
}
.mip#advantages1 .row {
  margin-bottom: 100px;
}
.mip#advantages1 .row:first-of-type {
  margin-bottom: 50px;
}
.mip#advantages1 .img {
  display: flex;
  justify-content: center;
}
.mip#advantages1 h2 {
  margin-bottom: 2.5rem;
}
.mip#advantages1 p {
  margin-bottom: 5rem;
}
.mip#extras {
  background: rgba(235, 235, 235, 0.25);
}
.mip#extras .container {
  margin: 0 auto 9rem auto;
  padding: 10rem 0;
}
.mip#advantages2 {
  margin-bottom: 8rem;
}
.mip#advantages2 h3 {
  margin-bottom: 2rem;
}
.mip#advantages2 .col {
  height: 100%;
  padding: 40px 20px 0 20px;
}
.mip#advantages2 .col-0 {
  background: #ffe9f0;
}
.mip#advantages2 .col-1 {
  background: #faecdb;
}
.mip#advantages2 .col-2 {
  background: #d6ebe4;
}
.mip#features {
  background: rgba(235, 235, 235, 0.25);
}
.mip#features .container {
  margin: 0 auto;
  padding: 10rem 0;
}
.mip#features .title {
  font-weight: 500;
}
.mip#features .small {
  color: darkgray;
}
.mip.study {
  padding: 30px 25px;
  box-sizing: border-box;
  outline: none !important;
}
@media screen and (min-width: 25em) {
  .mip.study {
    padding: 50px 35px;
  }
}
@media screen and (min-width: 48em) {
  .mip.study {
    padding: 58px 51px;
  }
}
@media screen and (max-width: 47.933em) {
  .mip.study {
    margin: 0 !important;
  }
}
.mip.study#phone {
  background-color: #ffe9f0;
}
.mip.study#deux {
  background-color: #d6ebe4;
}
.mip.study#gotmilk {
  background-color: #dfd6eb;
}
.mip.study#gotmilk .row {
  align-items: stretch;
}
.mip.study .left-col {
  position: relative;
}
@media screen and (min-width: 64em) {
  .mip.study p {
    margin-bottom: 3rem;
  }
}
.mip.study img {
  max-width: 100%;
}
.mip.study .testimonial {
  padding: 25px;
  background: white;
  margin-bottom: 24px;
}
@media screen and (min-width: 64em) {
  .mip.study .testimonial {
    margin-bottom: 0;
  }
}
.mip.study .testimonial p {
  font-size: 0.875rem;
  margin: 0;
}
.mip.study .testimonial p.author {
  margin-top: 20px;
  line-height: 1.6rem;
}
.mip.study .testimonial img {
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 20px;
}
.mip.study .nums {
  display: flex;
  flex-direction: row;
}
.mip.study .num {
  margin-left: 50px;
  padding: 65px 0;
}
.mip.study .num:first-child {
  margin-left: 0;
}
.mip.study .num p {
  margin: 5px 0;
}
.mip.study .num .title {
  font-size: 2rem;
  font-weight: 400;
}
.mip.study .num .description {
  font-size: 0.8rem;
  color: #797979;
}
.mip.cta {
  animation: crazy 2s infinite;
  text-align: center;
  padding: 100px 0px 120px;
}
@media screen and (min-width: 75em) {
  .mip.cta {
    padding: 160px 0px 180px;
  }
}
.mip.cta p {
  margin-bottom: 70px;
}
#testimonial {
  border-bottom: 1px solid #141514;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media screen and (min-width: 75em) {
  #testimonial {
    padding-top: 80px;
    padding-bottom: 120px;
  }
}
#testimonial .quote {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.3rem;
  line-height: 1.5rem;
}
#testimonial img {
  height: 40px;
  width: auto;
  vertical-align: middle;
  margin-right: 20px;
} /*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0px 0px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}
.hamburger-box {
  width: 20px;
  height: 16px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 20px;
  height: 2px;
  background-color: #000;
  border-radius: 0px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -7px;
}
.hamburger-inner::after {
  bottom: -7px;
}
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease,
    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease,
    transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Circular-Loom";

  src: url("https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Book-cd7d2bcec649b1243839a15d5eb8f0a3.woff2")
    format("woff2");
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: "Circular-Loom";

  src: url("https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Medium-d74eac43c78bd5852478998ce63dceb3.woff2")
    format("woff2");
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "Circular-Loom";

  src: url("https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Bold-83b8ceaf77f49c7cffa44107561909e4.woff2")
    format("woff2");
}

@font-face {
  font-weight: 900;
  font-style: normal;
  font-family: "Circular-Loom";

  src: url("https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Black-bf067ecb8aa777ceb6df7d72226febca.woff2")
    format("woff2");
}
</style>
